/**
 * Stuff that is not project specific. Should not import external libraries,
 * at least not large ones.
 */
import { Browser, detect } from "detect-browser";

type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
type CookieSameSite = "Lax" | "Strict" | "None";

type DocumentListener = {
    (document: Document): void;
};

interface CookieObject {
    [key: string]: string | undefined;
}

interface FormDataAttrs {
    [key: string]: string | Blob;
}

interface BrowserVersion {
    name: Browser;
    minVersion: number;
}

/**
 * Dynamically add rows to inline forms.
 *
 * @param list List element containing the items
 * @param rowSelector CSS selector to identify rows within that list
 * @param prefix Prefix used by Django when creating this form
 * @param rowsToAdd Number of new rows to insert
 * @param rowCallback Hook to do unspeakable things to the freshly cloned row
 */
export function addInlineRows(
    list: Element,
    rowSelector: string,
    prefix: string,
    rowsToAdd: number = 5,
    rowCallback?: (row: HTMLElement) => any
) {
    const totalForms = document.getElementById(`id_${prefix}-TOTAL_FORMS`);
    const lastRow = list?.querySelector(`${rowSelector}:last-child`);

    if (
        !(totalForms instanceof HTMLInputElement) ||
        !(lastRow instanceof HTMLElement)
    ) return;

    const initialForms = parseInt(totalForms.value);

    for (let idx = initialForms; idx < (initialForms + rowsToAdd); idx++) {
        const clone = lastRow.cloneNode(true) as HTMLElement;
        clone.querySelectorAll(`[id^="id_${prefix}"]`).forEach(elem => {
            if (elem instanceof HTMLInputElement) {
                const oldId = elem.id;
                const suffix = oldId.match(/\d+-(.*)$/)![1];
                const newName = prefix + "-" + idx.toString() + "-" + suffix;
                const newId = "id_" + newName;
                elem.name = newName;
                elem.id = newId;
                elem.value = "";
                elem.checked = false;
                elem.disabled = false;
                clone.querySelector(`label[for="${oldId}"]`)?.setAttribute("for", newId);
            }
        });
        clone.classList.add("new");
        list.append(clone);
        if (rowCallback) rowCallback(clone);
    }
    totalForms.value = (initialForms + rowsToAdd).toString();
}


export function clearDjangoFormErrors(form: HTMLFormElement) {
    form.querySelectorAll(".is-invalid").forEach(elem => elem.classList.remove("is-invalid"));
    form.querySelectorAll(".invalid-feedback").forEach(elem => elem.remove());
}


/**
 * Disabled links, buttons, etc.
 */
function handleDisabledElementClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
}

export function disableElement(elem: Element | null) {
    elem?.addEventListener("click", handleDisabledElementClick);
    elem?.classList.add("disabled");
    if (hasDisabledAttr(elem)) elem.disabled = true;
}

export function enableElement(elem: Element | null) {
    elem?.removeEventListener("click", handleDisabledElementClick);
    elem?.classList.remove("disabled");
    if (hasDisabledAttr(elem)) elem.disabled = false;
}

export function isDisabled(elem: Element): boolean {
    return elem.hasAttribute("disabled") || elem.classList.contains("disabled");
}

/**
 * Duck typing because there are ridiculously many element types that have
 * "disabled" attribute, but no one interface to refer to them by (until now).
 */
export function hasDisabledAttr(value: unknown): value is HasDisabledAttr {
    return !!(
        value &&
        value instanceof Element &&
        typeof Reflect.get(value, "disabled") === "boolean"
    );
}


/**
 * Return all breakpoints that are applicable to the current screen width,
 * in the "mobile first" paradigm. So for a 500px screen it would only return
 * ["xs"], but for a 1000px screen ["xs", "sm", "lg", "xl"].
 */
export function getCurrentBreakpoints(): Breakpoint[] {
    const width = window.visualViewport?.width;
    const result: Breakpoint[] = ["xs"];
    if (width != undefined) {
        if (width >= 576) result.push("sm");
        if (width >= 768) result.push("md");
        if (width >= 992) result.push("lg");
        if (width >= 1200) result.push("xl");
        if (width >= 1400) result.push("xxl");
    }
    return result;
}


/**
 * Hook into this whenever we need the DOM to be fully loaded before doing
 * something (NB: just the DOM, not necessarily all images etc.)
 */
export function onDOMLoaded(listener: DocumentListener) {
    if (document.readyState === "loading") {
        document.addEventListener("DOMContentLoaded", () => listener(document));
    }
    else listener(document);
}


/**
 * Like onDOMLoaded(), but runs when the whole document, including dependent
 * resources (images, CSS, etc) has loaded.
 */
export function onPageLoaded(listener: DocumentListener) {
    if (document.readyState !== "complete") {
        window.addEventListener("load", () => listener(document));
    }
    else listener(document);
}


/**
 * Sets innerHTML for an element and executes scripts within it.
 * Source: https://stackoverflow.com/a/47614491/14311882
 *
 * This is work in progress; somehow, Jquery's method does a much better job
 * at executing the stuff that should be executed, and not the stuff that
 * shouldn't. Case in point: expand multiple area/room tabs in student detail
 * view, and it starts to go bananas with dynamic fetching of graphs etc.
 */
export function setInnerHTML(elem: Element, html: string) {
    elem.innerHTML = html;

    Array.from(elem.querySelectorAll("script")).forEach(oldScriptElem => {
        const newScriptElem = document.createElement("script");
        const scriptText = document.createTextNode(oldScriptElem.innerHTML);

        Array.from(oldScriptElem.attributes).forEach(attr => {
            newScriptElem.setAttribute(attr.name, attr.value);
        });
        newScriptElem.appendChild(scriptText);
        oldScriptElem.parentNode?.replaceChild(newScriptElem, oldScriptElem);
    });
}


/** Convert "foo-and-bar" or "foo_and_bar" to "fooAndBar". */
export function toCamelCase(str: string): string {
    str = str.replace(/-(\w)/g, (_, p1) => { return p1.toUpperCase() });
    str = str.replace(/_(\w)/g, (_, p1) => { return p1.toUpperCase() });
    return str;
}


export function windowReachesBreakpoint(breakpoint: Breakpoint): boolean {
    return getCurrentBreakpoints().indexOf(breakpoint) != -1;
}


export function getMaxScrollTop(elem: Element): number {
    const extraOffset = elem instanceof HTMLElement ? parseInt(elem.dataset.extraOffset || "0") || 0 : 0;

    let top = elem.getBoundingClientRect().top - extraOffset;
    top -= getMinVisibleYPos(elem);
    return top;
}


/**
 * Scroll to element, adjusted for height of header etc., and different screen
 * widths. Set `data-extra-offset` attribute on element to add extra offset
 * (that is, to scroll down _less_ ... this could be worded better).
 *
 * Obviously we will only do this for one element per page, even if there
 * are multiple elements with this class.
 */
export function scrollToElement(elem: Element) {
    // Let's add an extra 10px because it looks nicer:
    window.scrollBy({top: getMaxScrollTop(elem) - 10, behavior: "smooth"});
}


function getScrollingContainer(elem: Element, horizontal: boolean): Element {
    const property = horizontal ? "overflow-x" : "overflow-y";
    var currentElement: Element | null = elem;

    while (currentElement != null && currentElement != document.body) {
        const propertyValue = window.getComputedStyle(currentElement).getPropertyValue(property);
        if (propertyValue == "scroll" || propertyValue == "auto")
            return currentElement;
        else
            currentElement = currentElement.parentElement;
    }
    if (!(document.scrollingElement instanceof Element))
        throw new Error("document.scrollingElement is not Element");
    return document.scrollingElement;
}


export function getHorizontalScrollingContainer(elem: Element): Element {
    return getScrollingContainer(elem, true);
}


export function getVerticalScrollingContainer(elem: Element): Element {
    return getScrollingContainer(elem, false);
}


/**
 * A primitive polyfill that sets or emulates a one-shot "scrollend" event
 * listener. For browsers that do not support "scrollend" events, it instead
 * waits for a "scroll" event, then waits for 250 more milliseconds to give
 * scroll a chance to complete, runs the callback once, then removes the
 * listener.
 */
export function addScrollEndListener(target: Element | GlobalEventHandlers, onScrollEnd: () => void) {
    // Source: https://caniuse.com/mdn-api_element_scrollend_event
    const scrollEndCapable: BrowserVersion[] = [
        { name: "android", minVersion: 114 },
        { name: "firefox", minVersion: 109 },
        { name: "chrome", minVersion: 114 },
        { name: "edge", minVersion: 114 },
    ];
    const browser = detect();
    const majorVersion = browser && browser.version ? parseInt(browser.version.split(".")[0]) : null;
    const isScrollEndCapable =
        majorVersion ? scrollEndCapable.find(bv => browser?.name == bv.name && majorVersion >= bv.minVersion) != undefined : false;

    if (isScrollEndCapable) {
        target.addEventListener("scrollend", () => { onScrollEnd() }, { once: true });
    } else {
        let timeout: number | undefined = undefined;
        const callback = () => {
            if (timeout == undefined) {
                timeout = window.setTimeout(() => {
                    onScrollEnd();
                    target.removeEventListener("scroll", callback);
                }, 250);
            }
        };
        target.addEventListener("scroll", callback);
    }
}


export function scrollIntoView(elem: Element, onScrollEnd?: () => void) {
    let shouldScrollVertical = false;
    let shouldScrollHorizontal = false;
    let scrolledVertical = false;
    let scrolledHorizontal = false;

    const onContainerScrolled = () => {
        if ((!shouldScrollHorizontal || scrolledHorizontal) && (!shouldScrollVertical || scrolledVertical)) {
            const rect = elem.getBoundingClientRect();
            let scrollBy = 0;
            if (rect.top < 0) scrollBy = -rect.top + 8;
            else if (rect.bottom > window.innerHeight) scrollBy = rect.bottom - window.innerHeight - 8;

            if (Math.abs(scrollBy) > 1) {
                addScrollEndListener(window, () => { onScrollEnd?.apply(null) });
                window.scrollBy({top: scrollBy, behavior: "smooth"});
            }
            else onScrollEnd?.apply(null);
        }
    };

    const verticalContainer = getVerticalScrollingContainer(elem);
    const horizontalContainer = getHorizontalScrollingContainer(elem);
    const rect = elem.getBoundingClientRect();
    const verticalRect = verticalContainer.getBoundingClientRect();
    const horizontalRect = horizontalContainer.getBoundingClientRect();

    const shouldScrollLeft = rect.left < horizontalRect.left && horizontalContainer.scrollLeft > 0;
    const shouldScrollRight = (
        rect.right > horizontalRect.right &&
        horizontalContainer.scrollWidth > horizontalContainer.clientWidth + horizontalContainer.scrollLeft
    );
    const shouldScrollDown = rect.top < verticalRect.top && verticalContainer.scrollTop > 0;
    const shouldScrollUp = (
        rect.bottom > verticalRect.bottom &&
        verticalContainer.scrollHeight > verticalContainer.clientHeight + verticalContainer.scrollTop
    );

    let scrollByVertical = 0;
    let scrollByHorizontal = 0;

    if (shouldScrollDown) scrollByVertical = rect.top - verticalRect.top - 8;
    else if (shouldScrollUp) scrollByVertical = rect.bottom - verticalRect.bottom + 8;

    if (shouldScrollLeft) scrollByHorizontal = rect.left - horizontalRect.left - 8;
    else if (shouldScrollRight) scrollByHorizontal = rect.right - verticalRect.right + 8;

    shouldScrollHorizontal = Math.abs(scrollByHorizontal) > 1;
    shouldScrollVertical = Math.abs(scrollByVertical) > 1;

    if (shouldScrollVertical) {
        addScrollEndListener(verticalContainer, () => {
            scrolledVertical = true;
            onContainerScrolled();
        });
        verticalContainer.scrollBy({top: scrollByVertical, behavior: "smooth"});
    }
    if (shouldScrollHorizontal) {
        addScrollEndListener(horizontalContainer, () => {
            scrolledHorizontal = true;
            onContainerScrolled();
        });
        horizontalContainer.scrollBy({left: scrollByHorizontal, behavior: "smooth"});
    }
    onContainerScrolled();
}


/**
 * Get the highest bottom position of any present .sticky-content elements
 * (including the page header). This is the Y position where sticky content
 * ends, and thus the position where main content first becomes visible.
 *
 * @param elem Get the minimum visible Y position _for this element_, meaning:
 * if it is contained in one of the .sticky-content elements, don'ẗ include
 * that one in the calculation.
 */
export function getMinVisibleYPos(elem: Element): number {
    let pos = 0;
    const selectors = getCurrentBreakpoints().map(v => `.sticky-content-${v}`);
    selectors.push(".sticky-content");

    document.querySelectorAll(selectors.join(",")).forEach(sticky => {
        if (sticky instanceof HTMLElement && !sticky.contains(elem)) {
            const bottom = sticky.offsetHeight + (parseInt(getComputedStyle(sticky).top) || 0);
            if (bottom > pos) pos = bottom;
        }
    });

    return pos;
}


/**
 * Get available cookies for the current document, conveniently converted to
 * an object.
 */
export function getCookies(): CookieObject {
    return Object.fromEntries(
        document.cookie
            .split(";")
            .map(c => c.split("=").map(v => v.trim()))
    );
}


export function getCookie(name: string): string | undefined {
    return getCookies()[name];
}


export function setCookie(
    key: string,
    value: string,
    path = "/",
    maxAge = 60 * 60 * 24 * 365,
    sameSite: CookieSameSite = "Lax",
    secure = false
) {
    const secureString = secure ? "; Secure" : "";
    document.cookie = `${key}=${value}; path=${path}; max-age=${maxAge}; SameSite=${sameSite}${secureString}`;
}


export function deleteCookie(key: string, path = "/", sameSite: CookieSameSite = "Lax") {
    document.cookie = `${key}=; path=${path}; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=${sameSite}`;
}


export function getCsrfToken(): string {
    const tokenElem = document.querySelector("[name=csrfmiddlewaretoken]");
    if (tokenElem instanceof HTMLInputElement) return tokenElem.value;
    else throw Error("No CSRF token <input> present.")
}


export function fetchWithCsrf(input: RequestInfo, method: string, formData?: FormData): Promise<Response> {
    const finalFormData = formData || new FormData();
    finalFormData.set("csrfmiddlewaretoken", getCsrfToken());
    return fetch(input, { method: method, body: finalFormData });
}


export function createFormData(attrs: FormDataAttrs): FormData {
    const formData = new FormData();
    for (let key in attrs) {
        formData.set(key, attrs[key]);
    }
    return formData;
}


export function repeat<T>(value: T, count: number): Array<T> {
    return (new Array(count)).fill(value);
}


/**
 * Resize dropdowns with class "limit-height" or "limit-height-[breakpoint]"
 * (like the top navbar ones) to end max 1rem above window bottom. We actually
 * use the bottom position of the parent .dropdown element rather than the top
 * of the .dropdown-menu, as the menu's bounding rect is empty until it has
 * been opened.
 *
 * This should be run once on load, then at window scroll events, when a modal
 * opens, and when dynamic content is loaded.
 */
export function setDropdownMaxHeights(root: Document | Element) {
    const selectors = getCurrentBreakpoints().map(v => `.dropdown.limit-height-${v}`);
    selectors.push(".dropdown.limit-height");

    root.querySelectorAll(selectors.join(",")).forEach(elem => {
        const menuElem = elem.getElementsByClassName("dropdown-menu").item(0);
        if (menuElem instanceof HTMLElement) {
            const bottom = elem.getBoundingClientRect().bottom;
            menuElem.style.maxHeight = `calc(100vh - ${bottom}px - 1rem)`;
        }
    });
}


export function createElement(
    tag: string,
    attrs?: Record<string, string>,
    textContent?: string | null,
    children?: Element[]
): HTMLElement {
    const elem = document.createElement(tag);

    for (const key in attrs) elem.setAttribute(key, attrs[key]);
    if (textContent) elem.textContent = textContent;
    if (children) elem.append(...children);
    return elem;
}


/** The only reasonable way to format a datetime. */
export function formatDateTime(date: Date, milliseconds = false): string {
    let ret = (
        `${date.getFullYear()}-` +
        `${date.getMonth().toString().padStart(2, "0")}-` +
        `${date.getDate().toString().padStart(2, "0")} ` +
        `${date.getHours().toString().padStart(2, "0")}:` +
        `${date.getMinutes().toString().padStart(2, "0")}:` +
        `${date.getSeconds().toString().padStart(2, "0")}`
    );
    if (milliseconds) ret += `.${date.getMilliseconds()}`;
    return ret;
}


/** Updates state rather than replacing it. */
export function replaceState(data: any, url?: string | URL | null) {
    const state = {
        ...(history.state instanceof Object ? history.state : {}),
        ...data,
    }

    history.replaceState(state, "", url);
}


/** Updates state rather than replacing it. */
export function pushState(data: any, url?: string | URL | null) {
    const state = {
        ...(history.state instanceof Object ? history.state : {}),
        ...data,
    }

    history.pushState(state, "", url);
}

/**
 * As soon as document.fonts is done loading, set class "fonts-loaded" on
 * <body>. Not used at the moment, but could maybe come in handy.
 */
export function initFontListener() {
    if (document.fonts.status == "loaded") {
        document.body.classList.add("fonts-loaded");
    }
    else document.fonts.addEventListener("loadingdone", () => {
        document.body.classList.add("fonts-loaded");
    });
}

/**
 * At click on elements with "data-scroll-to" attributes, we want to correctly
 * scroll to the element selected in this attribute.
 */
export function initScrollToLinks(root: ParentNode) {
    root.querySelectorAll("[data-scroll-to]").forEach(link => {
        if (link instanceof HTMLElement) {
            const selector = link.dataset.scrollTo;
            if (selector) {
                link.addEventListener("click", event => {
                    event.preventDefault();
                    const elem = document.querySelector(selector);
                    if (elem) scrollToElement(elem);
                });
            }
        }
    });
}

/**
 * Make sure any element with class="disable-on-click" gets disabled on click.
 * It's up to the user of this function to make sure they get re-enabled when
 * needed.
 */
export function initDisableOnClick(root: Document | Element) {
    root.querySelectorAll(".disable-on-click").forEach(elem => elem.addEventListener("click", () => {
        disableElement(elem);
    }));
}


/**
 * Bootstrap's Modal, as well as our own hideVerticalScrollbar(), sets right
 * padding on <body> to compensate for the hidden scrollbar, which offsets our
 * fixed header image. So we must in turn compensate for that.
 */
export function adjustHeader(scrollbarHidden: boolean) {
    const header = document.querySelector("header");

    if (header) {
        if (scrollbarHidden) {
            const headerOffset = Math.abs((window.innerWidth - document.documentElement.clientWidth) / 2);
            if (headerOffset > 0) header.style.backgroundPositionX = `calc(50% - ${headerOffset}px)`;
        } else {
            header.style.backgroundPositionX = "center";
        }
    }
}


export function hideVerticalScrollbar() {
    const scrollbarWidth = Math.abs(window.innerWidth - document.documentElement.clientWidth);

    adjustHeader(true);
    document.body.style.overflow = "hidden";
    if (scrollbarWidth > 0) document.body.style.paddingRight = `${scrollbarWidth}px`;
}


export function showVerticalScrollbar() {
    adjustHeader(false);
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}


export function getCombinedRect(elem: Element): DOMRect {
    const rect = elem.getBoundingClientRect();
    for (const child of elem.children) {
        const childRect = getCombinedRect(child);
        if (childRect.width > 0 && childRect.height > 0) {
            rect.x = Math.min(rect.x, childRect.x);
            rect.y = Math.min(rect.y, childRect.y);
            rect.height = Math.max(rect.bottom - rect.top, childRect.bottom - rect.top);
            rect.width = Math.max(rect.right - rect.left, childRect.right - rect.left);
        }
    }
    return rect;
}
